export const searchTags = {
  items: [
    {
      hasSynonyms: true,
      isModeratorOnly: false,
      isRequired: false,
      count: 630181,
      name: 'c++',
    },
    {
      hasSynonyms: true,
      isModeratorOnly: false,
      isRequired: false,
      count: 48533,
      name: 'c++11',
    },
    {
      hasSynonyms: true,
      isModeratorOnly: false,
      isRequired: false,
      count: 27298,
      name: 'visual-c++',
    },
    {
      hasSynonyms: true,
      isModeratorOnly: false,
      isRequired: false,
      count: 6184,
      name: 'c++14',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 5841,
      name: 'c++-cli',
    },
    {
      hasSynonyms: true,
      isModeratorOnly: false,
      isRequired: false,
      count: 4193,
      name: 'c++17',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 2693,
      name: 'c++builder',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 1103,
      name: 'objective-c++',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 735,
      name: 'dev-c++',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 732,
      name: 'c++-cx',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 728,
      name: 'c++03',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 651,
      name: 'c++-standard-library',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 591,
      name: 'libstdc++',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 484,
      name: 'c++98',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 440,
      name: 'managed-c++',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 376,
      name: 'libc++',
    },
    {
      hasSynonyms: true,
      isModeratorOnly: false,
      isRequired: false,
      count: 373,
      name: 'turbo-c++',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 365,
      name: 'visual-c++-2010',
    },
    {
      hasSynonyms: true,
      isModeratorOnly: false,
      isRequired: false,
      count: 309,
      name: 'c++20',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 241,
      name: 'llvm-c++-api',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 234,
      name: 'c++-concepts',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 233,
      name: 'c++-winrt',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 211,
      name: 'borland-c++',
    },
    {
      hasSynonyms: true,
      isModeratorOnly: false,
      isRequired: false,
      count: 198,
      name: 'visual-c++-2008',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 157,
      name: 'c++-amp',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 154,
      name: 'c++-faq',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 151,
      name: 'c++builder-6',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 123,
      name: 'visual-c++-2012',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 111,
      name: 'c++builder-2010',
    },
    {
      hasSynonyms: false,
      isModeratorOnly: false,
      isRequired: false,
      count: 110,
      name: 'visual-c++-2005',
    },
  ],
  hasMore: true,
  quotaMax: 300,
  quotaRemaining: 275,
};
